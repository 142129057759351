import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/annual-report/post-layout/post-layout.tsx";
import Chart from './chart.tsx';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <blockquote>
      <p parentName="blockquote">{`The mission of the Admissions Office at Pechersk School International, Kyiv is to achieve general school enrollment goals while providing exceptional service to future students and their families, along with maintaining diversity of the student body and supporting the work of the Primary and Secondary school Principals.`}</p>
    </blockquote>
    <p>{`One of the main objectives of the Admissions Manager is to provide the best services for applicants, students and their families, through implementing online technologies to completing all tasks and processes related to admission and registration. PSI’s registration database, Veracross, which was implemented a few years ago, is an important tool in various processes:`}</p>
    <ul>
      <li parentName="ul">{`Application to the school by new interested students’ families`}</li>
      <li parentName="ul">{`Re-enrollment for current students`}</li>
      <li parentName="ul">{`Registration for the upcoming school year for the enrolled families`}</li>
    </ul>
    <p>{`The impact of the pandemic meant temporary restrictions for campus tours, connected with the safety measures, and so a Virtual Admissions Portal was introduced by PSI for new families, who were interested in discovering more about the school programmes and curriculum, curious about campus life, extracurricular activities, additional programmes and student support. Using this portal, the possibility to ask questions or to arrange meetings with the admissions manager and school principals became easily accessible for all parents during the uncertain 2020 - 2021 SY.`}</p>
    <p>{`PSI has grown from an initial enrollment of 47 students to a school of just over 500 students since it opened in 1996. Enrolment has been quite stable and has grown every year, arriving at a total number of 510 students at the end of the school year.  This positive outcome is despite the fact that the 2020 / 21 School Year was affected by the Worldwide Pandemic crisis. At PSI the goal to ‘grow the school's healthy enrolment’is one of our priorities for the 2021 / 22 school year however, there are no plans to exceed our maximum capacity of 550 students.`}</p>
    <Chart mdxType="Chart" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      